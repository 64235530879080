<template>
  <div id="login-wrapper" style="background-image: url(https://images.unsplash.com/photo-1526367790999-0150786686a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80)">
    <div class="logo-box">
      <img src="../../assets/images/logo.svg" alt="Webmagnat Logo">
    </div>
    <b-form @submit.prevent="login" class="login-form" v-if="showLogin">
      <b-form-group
          class="login-form-group"
          id="input-group-1">
        <b-form-input
            id="login-email"
            v-model="form.email"
            type="email"
            required
            placeholder=""
        ></b-form-input>
        <label for="login-password"> Email </label>
      </b-form-group>

      <b-form-group
          class="login-form-group"
          id="input-group-2">
        <b-form-input
            name="password"
            :type="passwordType"
            id="login-password"
            v-model="form.password"
            required
            placeholder=""
        ></b-form-input>
        <label for="login-password"> {{ $t('recover_password.password') }}: </label>
        <i class="view-password fas" :class="[passwordIcon]" @click="hidePassword = !hidePassword"></i>
      </b-form-group>
      <p class="form-error" v-if="loginForm.error"> {{ loginForm.errorMessage }} </p>
      <div class="login-button-wrapper">
        <b-button class="app-button login-button" type="submit"> SIGN IN </b-button>
      </div>
    </b-form>
    <b-form action="" @submit.prevent="recoverPassword" class="recover-form" v-if="!showLogin">
      <b-form-group class="recover-form-group">
        <b-input
            type="email"
            id="recover-email"
            class="recover-form__input"
            v-model="recoverForm.email"
            required
            placeholder="">
        </b-input>
        <label for="recover-email" class="recover-form__label"> Email </label>
      </b-form-group>
      <div class="recover-form__button-container">
        <b-button class="app-button login-button recover-form__button" type="submit"> {{ $t('recover_password.recover') }} </b-button>
      </div>
      <p class="form-error" v-if="recoverForm.error"> {{ recoverForm.errorMessage }} </p>
      <p class="form-success" v-if="recoverForm.success"> {{ $t('recover_password.mail_sent') }} </p>
    </b-form>
    <a href="#" @click.prevent="showLogin = true" class="recover__link" v-if="!showLogin"> {{ $t('recover_password.back') }} </a>
    <a href="#" @click.prevent="showLogin = false" class="recover__link" v-if="showLogin"> {{ $t('recover_password.forgot') }} </a>
  </div>
</template>

<script>
import RecoverService from './../../api-services/recover.service'
export default {
  name: 'Login',
  data () {
    return {
      showLogin: true,
      form: {
        email: '',
        password: ''
      },
      loginForm: {
        error: false,
        errorMessage: ''
      },
      recoverForm: {
        email: '',
        success: false,
        error: false,
        errorMessage: ''
      },
      activeForm: 1,
      error: null,
      hidePassword: true
    }
  },
  methods: {
    login: function () {
      if (this.form.password.length > 0) {
        this.$store.dispatch('showLoading')
        this.error = false
        let data = {
          email: this.form.email,
          password: this.form.password
        }
        this.$store.dispatch('login', data)
          .then((response) => {
            this.$store.dispatch('hideLoading')
            this.loginForm.error = false
            this.loginForm.errorMessage = ''
            this.$router.push('/orders')
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 422) {
              this.loginForm.error = true
              this.loginForm.errorMessage = error.response.data.error
            }
            this.$store.dispatch('hideLoading')
            console.log(error.response)
          })
      }
    },
    recoverPassword: function () {
      if (this.recoverForm.email.length > 0) {
        let data = {
          email: this.recoverForm.email
        }
        this.recoverForm.error = false
        this.recoverForm.success = false
        RecoverService.recover(data)
          .then((response) => {
            this.recoverForm.success = true
          })
          .catch((error) => {
            this.recoverForm.errorMessage = error.response.data.error
            this.recoverForm.error = true
          })
      }
    },
    showForm: function (form) {
      this.activeForm = form
    }
  },
  computed: {
    passwordType () {
      return this.hidePassword ? 'password' : 'text'
    },
    passwordIcon () {
      return this.hidePassword ? 'fa-eye' : 'fa-eye-slash'
    }
  },
  mounted () {
    // this.$store.dispatch('hideLoading')
    // console.log(this.$store)
  },
  created () {
    this.$store.dispatch('hideLoading')
    this.activeForm = 1
    // if (this.$store.getters.isLoggedIn) {
    //   this.$router.push('/account')
    // }
  }
}
</script>
